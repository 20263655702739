import React from "react";
import logo from "../../assets/Logo.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide1 from '../../assets/images/authimg.jpeg'
import Slide2 from '../../assets/images/swipe2.png'
import Slide3 from '../../assets/images/swipe3.png'
import { useLocation } from "react-router-dom";
import { Access } from "../../components/forms/Access";

import "swiper/css";
import { Autoplay } from "swiper";


export default function Auth() {
     const location = useLocation();

     const handleRedirect = () => {
          window.open("https://heala.ng/terms/", "_blank");
     }  
     

     return (
          <div className="h-screen w-screen mid:flex">
               <div className="flex-1 h-screen mid:block hidden">
                    <Swiper
                         slidesPerView={1}
                         
                    >
                         <SwiperSlide>
                              <img src={Slide1} className="h-full w-full object-cover"/>
                         </SwiperSlide>
                        
                    </Swiper>
               </div>
               <div className="mid:max-w-[582px] w-full mid:w-2/4 flex flex-col px-6 phones:px-12 h-full gap-16">
                    <div className={`w-full flex-1 flex ${(location.pathname === "/otp" || location.pathname === "/forgotpassword") ? '' : 'items-center'}`}>
                         {location.pathname === "/access" && <Access />}
                    </div>
                    <div className="flex flex-col items-center">
                         <div className="flex justify-center items-center space-x-1">
                              <p className="leading-5 m-0">powered by</p>
                              <img src={logo} alt="heala logo" />
                         </div>
                         <p onClick={handleRedirect} className="mid:block hidden text-primary cursor-pointer text-[13px] m-0 mt-[5px] mb-[10px]">
                              Heala's Terms & Conditions
                         </p>
                    </div>
               </div>
          </div>
     );
}
