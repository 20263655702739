import React, { useState, useContext } from 'react';
import { ConsultationContext } from '../../context/consultationContext';
import { PrescriptionContext } from '../../context/prescriptionContext';
import { TestContext } from '../../context/testContext';
import { useQuery } from '@apollo/client';
import useAlert from '../../hooks/useAlert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GET_DOCTOR_AVAILIBILITY_FOR_DATE } from '../../api/graphQL/query';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Ellipse } from '../../assets/svgs/ellipse.svg';
import { ReactComponent as Ellipses } from '../../assets/svgs/1.svg';
import { ReactComponent as Larr } from '../../assets/svgs/larr.svg';
import { ReactComponent as Larrr } from '../../assets/svgs/larrr.svg';
import { ReactComponent as Rarr } from '../../assets/svgs/rarr.svg';
import { ReactComponent as Rarrr } from '../../assets/svgs/rarrr.svg';
import Drawers from '../drawers';
import Selectable from './selectable';
import Consultsummary from '../consult/consultsummary';
import Consultpref from '../consult/consultpref';
import Tablemodal from './modal';
import Selectprescribe from '../prescribe/selectprescribe';
import Selectest from '../test/selectest';
import { numberWithCommas, formatDateTime, convertFirstLetterToUpperCase, classNames, statusStyles, convertToMinutes, convertToYear, stringAvatar } from '../helperFunctions';
import SkeletonLoader from './skeletonLoader';

export default function Tabledashboard({tableHead, index, setPage, page, loading, profileLoading}) {
    const {displayAlert} = useAlert();
    const authToken = localStorage.getItem('authToken');
    const { consultation } = useContext(ConsultationContext);
    const { prescription } = useContext(PrescriptionContext);
    const { test } = useContext(TestContext);
    
    // Number of items to display per page
    const dataPerPage = 10;
    
    const [currentPage, setCurrentPage] = useState(1);
    const [draw, setDraw] = useState(false);
    const [pref, setPref] = useState(false);
    const [prescribe, setPrescribe] = useState(false);
    const [testDrawer, setTestDrawer] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [testsData, setTestsData] = useState(null);
    const [presRowData, setPresRowData] = useState(null);
    const [reschedule, setReschedule] = useState(null);
    const [modal, setModal] = useState(null);
    const [time, setTime] = useState([]);
    const [docId, setDocId] = useState('');
    const [dates, setDates] = useState('')
    
    const formattedDate = convertToYear(dates);
    
    const { error: docErr, data: docData, loading: docLoad } = useQuery(GET_DOCTOR_AVAILIBILITY_FOR_DATE, {
        skip: !docId,
        variables: { id: docId, date: formattedDate },
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });
    
    //CONSULTATION DATA
    const consultationData = consultation?.data;
    const consultationFoot = consultation?.pageInfo;

    //PRESCRIPTION DATA
    const prescriptionData = prescription?.data;
    const prescriptionFoot = prescription?.pageInfo;

    //TEST DATA
    const testData = test?.referral;
    const testFoot = test?.pageInfo;
    
    // Total number of pages
    const totalPages = Math.ceil(prescriptionData?.length  / dataPerPage);

    const handleDrawer = () => {
        setPref(false);
        setOpenDrawer(false);
    }

    const handleConsultDrawer = (index, row) => {
        setOpenDrawer(true);
        setRowData(row);
    }
    
    const handleTestDrawer = (index, row) => {
        setTestDrawer(true);
        setTestsData(row);
    }
    
    const handlePrescribeDrawer = (index, row) => {
        setPrescribe(true);
        setPresRowData(row);
    }

    const handleReschedule = (id, data) => {
        setReschedule(id);
        setModal(data);
        setDates(data?.time);
        setDocId(data.doctorData._id);
        getDoctorsTimesByDate()
    }

    const getDoctorsTimesByDate = () => {
        setTime(docData?.getDoctorAvailabilityForDate?.times || []);
    };

    return (
        <>
            {loading || profileLoading ? (
                <SkeletonLoader tableHead={tableHead.length} rowsNum={10}/>
            ) : (
                <>
                    <TableContainer component={Paper} sx={{borderRadius:"8px"}}>
                        <Table>
                            <TableHead sx={{bgcolor:"#3e5ea9"}}>
                                <TableRow>
                                    {tableHead.map((t) => (
                                        <TableCell key={t} sx={{ color: '#FFFFFF', cursor:'pointer', fontWeight: '500', fontSize: '16px', fontFamily: 'Euclid Circular A' }}>{t}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            
                            {/* Table for Consult Board */}
                            {index === 'consult' && (
                                <TableBody>
                                    {consultationData?.length >= 1 ? consultationData?.map((row, index) => {
                                        const { formattedDate, formattedTime } = formatDateTime(row.time);
                                        let x = row.time?.split('').splice(16,5).join('')
                                        const [hours, minutes] = x.split(':').map(Number);

                                        // Calculate the new hours and minutes
                                        const newHours = (hours + 1) % 24;
                                        const newMinutes = minutes;
                                      
                                        // Format the new time with leading zeros
                                        const newTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
                                      
                                        return (
                                            <>
                                                <TableRow key={index}>
                                                    <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{formattedDate}</TableCell>
                                                    <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                        <div className='flex flex-col items-center justify-center'>
                                                            <span>{newTime}</span>
                                                            {row.consultationDuration && <span className='text-gray-900 text-[13px]'>{convertToMinutes(row?.consultationDuration)} mins</span>} 
                                                        </div>
                                                    </TableCell>
                                                    <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{convertFirstLetterToUpperCase(row.contactMedium) || "None"}</TableCell>
                                                    {!row?.doctorData?.firstName ? (
                                                        <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                            <div className='flex gap-[10px] items-center table-img'>
                                                                <Avatar sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}>Nil</Avatar>
                                                                <span>Nil</span>
                                                            </div>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                            <div className='flex gap-[10px] items-center table-img'>
                                                                {row.doctorData.picture ? (
                                                                    <img src={row.doctorData.picture} alt='doc'/>
                                                                ) : (
                                                                    <Avatar {...stringAvatar(row.doctorData.lastName, row.doctorData.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                                                                )}
                                                                <span>{row.doctorData.lastName} {row.doctorData.firstName}</span>
                                                            </div>
                                                        </TableCell>
                                                    )}
                                                    <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{(row.fee === null || row.fee === 0) ? "Nil" : `N${numberWithCommas(row?.fee)}`}</TableCell>
                                                    <TableCell onClick={() => handleConsultDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                        <span className={classNames(
                                                            statusStyles[convertFirstLetterToUpperCase(row.status === 'missed' ? 'Failed' : row.status)],
                                                            'p-1 rounded-lg'
                                                        )}>
                                                            {convertFirstLetterToUpperCase(row.status === 'missed' ? 'Failed' : row.status)}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell sx={{ cursor: 'pointer', color: 'royalblue' }} onClick={() => handleReschedule(index, row)}>
                                                       <Ellipse/>

                                                    </TableCell> 
                                                </TableRow>
                                                {reschedule === index && (
                                                    <Tablemodal setReschedule={setReschedule} setDraw={setDraw} draw={draw} modal={modal}/>
                                                )}
                                            </>
                                        )
                                    })  : <TableRow>
                                        <TableCell>No data</TableCell>
                                    </TableRow>}
                                </TableBody>
                            )}
                            
                            {/* Table for Prescription Board */}
                            {index === 'prescribe' && (
                                <TableBody>
                                    {prescriptionData?.map((row, index) => {
                                        const { formattedDate, formattedTime } = formatDateTime(row.patientData.updatedAt);
                                        
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{row.drugs[0].drugName}</TableCell>
                                                    {row?.doctorData?.firstName ? (
                                                        <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                            <div className='flex gap-[10px] items-center table-img'>
                                                                {row.doctorData.picture ? (
                                                                    <img src={row.doctorData.picture} alt='doc'/>
                                                                ) : (
                                                                    <Avatar {...stringAvatar(row.doctorData.lastName, row.doctorData.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                                                                )}
                                                                <span>{row.doctorData.lastName} {row.doctorData.firstName}</span>
                                                            </div>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                            <div className='flex gap-[10px] items-center table-img'>
                                                                <Avatar sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}>Nil</Avatar>
                                                                <span>Nil</span>
                                                            </div>
                                                        </TableCell>
                                                    )}
                                                    <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{formattedDate}</TableCell>
                                                    {/* <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                        <span className={classNames(
                                                            statusStyles[convertFirstLetterToUpperCase("ongoing")],
                                                            'p-1 rounded-lg'
                                                        )}>
                                                            {convertFirstLetterToUpperCase("ongoing")}
                                                        </span>
                                                    </TableCell> */}
                                                    <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ cursor: 'pointer', color:'royalblue' }}>
See Details
                                                    </TableCell> 
                                                </TableRow>
                                            )
                                        }
                                    )}
                                </TableBody>
                            )}
                                                
                            {/* Table for Test Board */}
                            {index === 'test' && (
                                <TableBody>
                                    {testData?.length >= 1 ? testData?.map((row, index) => {
                                        const { formattedDate, formattedTime } = formatDateTime(row.updatedAt);
                                        return ( 
                                            <TableRow key={index}>
                                                <TableCell onClick={() => handleTestDrawer(index, row)} sx={{ width: '200px', color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                    {row?.tests?.map((t) => (
                                                        <p key={t}>{t.name}</p>
                                                    ))}
                                                </TableCell>
                                                {!row.doctorData.firstName ? (
                                                    <TableCell onClick={() => handlePrescribeDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                        <div className='flex gap-[10px] items-center table-img'>
                                                            <Avatar sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}>Nil</Avatar>
                                                            <span>Nil</span>
                                                        </div>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell onClick={() => handleTestDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                        <div className='flex gap-[10px] items-center table-img'>
                                                            {row.doctorData.picture ? (
                                                                <img src={row.doctorData.picture} alt='doc'/>
                                                            ) : (
                                                                <Avatar {...stringAvatar(row.doctorData.lastName, row.doctorData.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                                                            )}
                                                            <span>{row.doctorData.lastName} {row.doctorData.firstName}</span>
                                                        </div>
                                                    </TableCell>
                                                )}
                                                <TableCell onClick={() => handleTestDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>{formattedDate}</TableCell>
                                                {/* <TableCell onClick={() => handleTestDrawer(index, row)} sx={{ color: '#0C1322', cursor:'pointer', fontFamily: 'Euclid Circular A' }}>
                                                    <span className={classNames(
                                                        statusStyles[convertFirstLetterToUpperCase(row.status === 'missed' ? 'Failed' : row.status)],
                                                        'p-1 rounded-lg'
                                                    )}>
                                                        {convertFirstLetterToUpperCase(row.status === 'missed' ? 'Failed' : row.status)}
                                                    </span>
                                                </TableCell> */}
                                                <TableCell onClick={() => handleTestDrawer(index, row)} sx={{ cursor: 'pointer',color: 'royalblue'  }}>
                                                See Details
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : (
                                        <TableRow>
                                            <TableCell>No data</TableCell>
                                        </TableRow> 
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer> 
                                    
                    {/* Footer page number */}
                    {index === 'consult' && (
                        <div className='mt-6 flex flex-col tab:flex-row gap-2 tab:gap-0  justify-between items-center p-4'>
                            <div>{`Page ${consultationFoot?.page} of ${consultationFoot?.totalPages}`}</div>
                            {consultationFoot?.totalPages > 1 && (
                                <div className='flex items-center cursor-pointer gap-2 justify-center'>
                                    {consultationFoot.page > 1 && (
                                        <>
                                            <Larrr onClick={() => setPage(1)}/>
                                            <Larr onClick={() => setPage(page - 1)}/>        
                                        </>    
                                    )}
                                    <div className='flex gap-[11.5px] items-center'>
                                        {consultationFoot.totalPages <= 3 ? (
                                            Array.from({ length: consultationFoot.totalPages }).map((_, idx) => (
                                                <div
                                                    key={idx}
                                                    className={`${
                                                        consultationFoot.page === idx + 1 ? 'current-page' : ''
                                                    }`}
                                                >
                                                    <span onClick={() => setPage(idx + 1)}>{idx + 1}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <div>
                                                    <span onClick={() => setPage(1)} className={`${consultationFoot.page === 1 ? 'current-page' : ''}`}>1</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(2)} className={`${consultationFoot.page === 2 ? 'current-page' : ''}`}>2</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(3)} className={`${consultationFoot.page === 3 ? 'current-page' : ''}`}>3</span>
                                                </div>
                                                <div style={{color: 'royalblue' }}>
                                                See Details
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(consultationFoot.totalPages)} className={`${consultationFoot.page === consultationFoot.totalPages ? 'current-page' : ''}`}>
                                                        {consultationFoot.totalPages}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {consultationFoot.page < consultationFoot.totalPages && (
                                        <>
                                            <Rarr onClick={() => setPage(page + 1)} />
                                            <Rarrr onClick={() => setPage(consultationFoot.totalPages)} />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {index === 'test' && (
                        <div className='mt-6 flex flex-col tab:flex-row gap-2 tab:gap-0  justify-between items-center p-4'>
                            <div>{`Page ${testFoot?.page} of ${testFoot?.totalPages}`}</div>
                            {testFoot?.totalPages > 1 && (
                                <div className='flex items-center cursor-pointer gap-2 justify-center'>
                                    {consultationFoot.page > 1 && (
                                        <>
                                            <Larrr onClick={() => setPage(1)}/>
                                            <Larr onClick={() => setPage(page - 1)}/>
                                        </>    
                                    )}
                                    <div className='flex gap-[11.5px] items-center'>
                                        {testFoot.totalPages <= 3 ? (
                                            Array.from({ length: testFoot.totalPages }).map((_, idx) => (
                                                <div
                                                    key={idx}
                                                    className={`${
                                                        testFoot.page === idx + 1 ? 'current-page' : ''
                                                    }`}
                                                >
                                                    <span onClick={() => setPage(idx + 1)}>{idx + 1}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <div>
                                                    <span onClick={() => setPage(1)} className={`${testFoot.page === 1 ? 'current-page' : ''}`}>1</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(2)} className={`${testFoot.page === 2 ? 'current-page' : ''}`}>2</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(3)} className={`${testFoot.page === 3 ? 'current-page' : ''}`}>3</span>
                                                </div>
                                                <div style={{color: 'royalblue' }}>
                                                See Details
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(testFoot.totalPages)} className={`${testFoot.page === testFoot.totalPages ? 'current-page' : ''}`}>
                                                        {testFoot.totalPages}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {consultationFoot.page < consultationFoot.totalPages && (
                                        <>
                                            <Rarr onClick={() => setPage(page + 1)} />
                                            <Rarrr onClick={() => setPage(testFoot.totalPages)} />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    
                    {index === 'prescribe' && (
                        <div className='mt-6 flex flex-col tab:flex-row gap-2 tab:gap-0  justify-between items-center p-4'>
                            <div>{`Page ${prescriptionFoot?.page} of ${prescriptionFoot?.totalPages}`}</div>
                            {prescriptionFoot?.totalPages > 1 && (
                                <div className='flex items-center cursor-pointer gap-2 justify-center'>
                                    {consultationFoot.page > 1 && (
                                        <>
                                            <Larrr onClick={() => setPage(1)} />
                                            <Larr onClick={() => setPage(page - 1)}/>        
                                        </>    
                                    )}
                                    <div className='flex gap-[11.5px] items-center'>
                                    {prescriptionFoot.totalPages <= 3 ? (
                                            Array.from({ length: prescriptionFoot.totalPages }).map((_, idx) => (
                                                <div
                                                    key={idx}
                                                    className={`${
                                                        prescriptionFoot.page === idx + 1 ? 'current-page' : ''
                                                    }`}
                                                >
                                                    <span onClick={() => setPage(idx + 1)}>{idx + 1}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <div>
                                                    <span onClick={() => setPage(1)} className={`${prescriptionFoot.page === 1 ? 'current-page' : ''}`}>1</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(2)} className={`${prescriptionFoot.page === 2 ? 'current-page' : ''}`}>2</span>
                                                </div>
                                                <div>
                                                    <span onClick={() => setPage(3)} className={`${prescriptionFoot.page === 3 ? 'current-page' : ''}`}>3</span>
                                                </div>
                                                <div style={{color:'royalblue'}}>
See Details                                                
</div>
                                                <div>
                                                    <span onClick={() => setPage(prescriptionFoot.totalPages)} className={`${prescriptionFoot.page === prescriptionFoot.totalPages ? 'current-page' : ''}`}>
                                                        {prescriptionFoot.totalPages}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {consultationFoot.page < consultationFoot.totalPages && (
                                        <>
                                            <Rarr onClick={() => setPage(page + 1)} />
                                            <Rarrr onClick={() => setPage(prescriptionFoot.totalPages)}/>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                                    
                    {/* Drawers */}
                    <Drawers openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} width='1000px' mdwidth='100%'>
                        <Consultsummary 
                            rowData={rowData} 
                            setOpen={setOpenDrawer} 
                            setPref={setPref} 
                            pref={pref}
                        />

                        <Drawers openDrawer={pref} setOpenDrawer={setPref} handleDrawer={handleDrawer}>
                            <Consultpref setPref={setPref} setOpen={setOpenDrawer} rowData={rowData} />
                        </Drawers>
                    </Drawers>
                    
                    <Drawers openDrawer={draw} setOpenDrawer={setDraw}>
                        <Selectable setDraw={setDraw} rowData={rowData} modal={modal} time={time}/>
                    </Drawers>
                    
                    <Drawers openDrawer={prescribe} setOpenDrawer={setPrescribe}>
                        <Selectprescribe data={presRowData} setPrescribe={setPrescribe}/>
                    </Drawers>
                    
                    <Drawers openDrawer={testDrawer} setOpenDrawer={setTestDrawer}>
                        <Selectest setTest={setTestDrawer} data={testsData} />
                    </Drawers>
                </>
            )}
        </>
    )
}