import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";
import GIF from '../assets/gif/gif.mov';
/* import { useContext } from "react";
import { AppContext } from "../App"; */

const Loader = ({ text, type = "inline" }) => {
  /* const { partnerInfo } = useContext(AppContext); */

  return (
    <>
      {type === "inline" && (
          <CircularProgress sx={{ /* color: partnerInfo?.widgetColor */ margin: "1rem" }} />
      )}
      {type === "fullPage" && (
        <Backdrop
          sx={{ color: "#fff", backgroundColor: "#f9f9f9", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 25,
              flexDirection: "column",
            }}
          >
            <video src={GIF} autoPlay loop muted style={{ width: "200px" }} />
            <Typography variant="h3" component="h3">
              {text}
            </Typography>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default Loader;
