import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { AppContext } from "../../App";
import { useQuery } from "@apollo/client";
import StartConsultation from "./Landing";
import { useParams } from "react-router-dom";
import { useFetchAPI } from "../../api/axios/axios";
import { GET_PARTNER_BY_SUBDOMAIN, DOCTOR_PROFILE } from "../../api/graphQL/query";

export const Home = () => {
  const { subdomain } = useParams();
  const { GET_PLANS } = useFetchAPI();
  const {
    setLoading,
    setToken,
    plans,
    setPlans,
    setPartnerInfo,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { error, data, loading } = useQuery(GET_PARTNER_BY_SUBDOMAIN, {
    variables: { subdomain: subdomain || "" },
  });

  const { error: doctorError, data: doctorData, loading: doctorLoading } = useQuery(DOCTOR_PROFILE, {
    variables: { id: subdomain || "" },
  });

  const providerId = data && data.getPartnerBySubdomain.providerId;
  localStorage.setItem("providerId", providerId);

  const apiKey = data && data.getPartnerBySubdomain.apiKey;
  localStorage.setItem("apiKey", apiKey);
  
  const { widgetLogo } =
  data?.getPartnerBySubdomain || {};
  localStorage.setItem("logo", widgetLogo);

  useEffect(() => {
    if (!data) return;
    setPartnerInfo(data?.getPartnerBySubdomain);
    sessionStorage.setItem(
      "partnerInfo",
      JSON.stringify(data?.getPartnerBySubdomain)
    );
    const apiKey = data?.getPartnerBySubdomain?.apiKey;
    setToken(apiKey);
    GET_PLANS(providerId, apiKey)
      .then((res) => {
        setPlans(res?.data?.plan || []);
      })
      .catch((error) => {
        setPlans([]);
        console.error("Failed to get plans:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isLoading = loading || plans === undefined;
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, plans]);

  useEffect(() => {
    ["paymentSuccessInfo", "consultationInfo", "psRef"].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }, []);

  if (!doctorData && (error && subdomain)) {
    return (
      <Loader type="fullpage" />
    );
  }

  if (doctorData) {
    localStorage.setItem('doctor', JSON.stringify(doctorData.doctorProfile));
    localStorage.setItem('isDoctor', 'true');
    localStorage.setItem('token', process.env.REACT_APP_API_KEY);
    localStorage.setItem('apiKey', process.env.REACT_APP_API_KEY);
    localStorage.setItem('providerId', process.env.REACT_APP_PROVIDER_KEY);
    setToken(process.env.REACT_APP_API_KEY);
    navigate(`/doctor/${subdomain}`);
  }

  if (loading || doctorLoading)
  return (
    <div className="h-screen flex justify-center items-center">
      <Loader type="fullPage"/>
    </div>
  );

  return (
    <>
      {data?.getPartnerBySubdomain?.category === null ||
        subdomain === undefined ||
        data?.getPartnerBySubdomain?.category === "hospital" ||
        data?.getPartnerBySubdomain?.category === "hmo" ? 
          <StartConsultation />
        : ''
      }
    </>
  );
};
