import React from 'react';

export default function Fullbar({children}) {

  return (
    <div className='flex-1 md:px-12 px-6 pt-4 md:pt-10 overflow-y-auto desktop:border-l'>
      {children}
    </div>
  )
}
