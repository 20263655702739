import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Input } from '../../components/Input';
import { CircularProgress } from "@mui/material";
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAvailableTimeSlot, convertToISOString, numberWithCommas } from '../../components/helperFunctions';
import { GET_SERVER_TIME } from '../../api/graphQL/query';
import { useFetchAPI } from '../../api/axios/axios';
import useAlert from '../../hooks/useAlert';
import { TextField } from "@mui/material";
import { DesktopDatePicker,MobileDatePicker } from "@mui/x-date-pickers";

import { AppContext } from '../../App';

const useStyles = makeStyles(() => ({
    submitButton: {
        marginTop: "18px !important",
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
            height: '44px'
        },
    },
}));

export const DocPreference = () => {
    const classes = useStyles();
    const { displayAlert } = useAlert();
    const { GET_DOCTOR_AVAILIBILITY_FOR_DATE, VALIDATE_EMAIL, CREATE_CONSULTATION, CREATE_PAYMENT_URL } = useFetchAPI();
    const { loading: appLoading, setLoading: setAppLoading } = useContext(AppContext);
    const defaultValue = dayjs()
    console.log({defaultValue})
    const data = localStorage.getItem('doctor');
    const doc = JSON.parse(data);
    const form2 = JSON.parse(localStorage.getItem('formData'));

    const providerId = localStorage.getItem("providerId");

    const localProfile = JSON.parse(localStorage.getItem('profile'));

    const navigate = useNavigate();
    const consultInfo = JSON.parse((localStorage.getItem('form1Data')));
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(form2?.email || '');
    const [date, setDate] = useState("");
    const [time, setTime] = useState(form2?.time || '');
    const [channel, setChannel] = useState(form2?.channel || '');
    const [emailError, setEmailError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [channelError, setChannelError] = useState('');
    const [availableTime, setAvailableTime] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [profile, setProfile] = useState({});
    const id = doc._id

    useEffect(() => {
        if (id) {
            const todayDate = dayjs().format('YYYY-MM-DD');
            setSelectedDate(todayDate);
            setDate(todayDate);
            getDoctorsTimesByDate(todayDate, id);
        }
        return;
    }, [id])

    useEffect(() => {
        if (Object.keys(profile).length > 0) {
            createConsultation();
        }
    }, [profile]);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleNext = () => {
        if (!email) {
            setEmailError('This field is required!');
        }
        if (!time) {
            setTimeError('This field is required!');
        }
        if (!channel) {
            setChannelError('This field is required!');
        }

        if (!email || !time || !channel) {
            return;
        }

        const form1Data = JSON.parse(localStorage.getItem('form1Data'));

        const form2Data = {
            email,
            date,
            time,
            channel,
        };

        const formData = {
            ...form1Data,
            ...form2Data,
        };
        localStorage.setItem('formData', JSON.stringify(formData)); 

        handleVerification(email);
    }

    const handleVerification = async (email) => {
        try {
            setLoading(true);
            const data = {
                email: email
            }
            const res = await VALIDATE_EMAIL(data);
            console.log(res, 'response');
            if (res?.data?.message === "Proile loaded successfully") {
                localStorage.setItem('profile', JSON.stringify(res?.data?.data));
                setProfile(res?.data?.data);
            } else {
                navigate('/doctor/information');
            }
        } catch (error) {
            navigate('/doctor/information');
        }
        setLoading(false);
    }

    const handlegetDoctors = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        setDate(formattedDate);
        getDoctorsTimesByDate(formattedDate, id);
    };

    const getDoctorsTimesByDate = async (date, docId) => {
        try {
            const res = await GET_DOCTOR_AVAILIBILITY_FOR_DATE(date, docId);
            setAvailableTime(res?.data?.data?.times || []);
        } catch (error) {
            displayAlert("error", "Error while trying to get available times.");
            console.error("Error from getDoctorsTimesByDate function:", error);
        }
    };

    const { error: serErr, data: serData, loading: serLoad } = useQuery(GET_SERVER_TIME);

    const getServerTime = serData?.getServerTime?.timeLagosJsDate;
    console.log(serData, "getServerTime")

    const currentDateL = getServerTime?.split('').splice(0, 15).join('');
    const currentDate = getServerTime
    var inputDate = date
    var dateComponents = inputDate.split("-");

    var year = dateComponents[0];
    var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
    var day = dateComponents[2];

    var months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var dateObj = new Date(year, month, day);
    var formattedDate = dateObj.toString().substr(0, 3) + " " + months[month] + " " + day + " " + year;
    console.log(currentDateL,"currentDatexyz",formattedDate,currentDate)

    const selectedSlot = currentDateL == formattedDate
        ? getAvailableTimeSlot(availableTime, currentDate)
        : availableTime;

    const isoString = convertToISOString(consultInfo?.date || date, consultInfo?.time || time);

    const createConsultation = async () => {

        function convertTime(inputTime) {
            const selectedDateObj = new Date(selectedDate); // Get the current date
            const [hours, minutes] = inputTime.split(':'); // Split the input time into hours and minutes
            const year = selectedDateObj.getFullYear(); // Get the current year
            const month = selectedDateObj.getMonth() + 1; // Get the current month (Note: Months are zero-based)
            const day = selectedDateObj.getDate(); // Get the current day
          
            // Create a new date string in the desired format
            const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours}:${minutes}:00+01:00`;
          
            return dateString;
          }

          const formattedTime = convertTime(time);

        try {
            setLoading(true);
            setAppLoading(true);
            const consultationVar = {
                consultationOwner: 'myself',
                patientData: {
                    email: consultInfo?.email || email,
                    firstName: profile?.firstName || localProfile?.firstName,
                    lastName: profile?.lastName || localProfile?.lastName,
                    phoneNumber: profile?.phoneNumber || localProfile?.phoneNumber,
                    gender: profile?.gender || localProfile?.gender,
                    hmoId: "",
                    image: "",
                    height: "0",
                    weight: "0",
                    bloodGroup: "",
                    genotype: "",
                },
                doctor: doc._id,
                discomfortLevel: consultInfo?.level,
                description: consultInfo?.aob,
                firstNotice: consultInfo?.expect,
                providerId: providerId,
                contactMedium: channel || consultInfo?.channel,
                time: formattedTime,
                symptoms: consultInfo?.batches?.length > 0
                    ? consultInfo?.batches.map((batch) => ({
                        name: batch,
                    }))
                    : [{ name: consultInfo?.symptoms }],
                type: "scheduled",
                providerId: providerId,
                timeOffset: "",
                status: "pending",
                createdThrough: "doctor-direct"
            }
            const res = await CREATE_CONSULTATION(consultationVar);
            localStorage.setItem("consultationInfo", JSON.stringify(res?.data?.data));

            setLoading(false);
            const paymentUrlResponse = await CREATE_PAYMENT_URL({
                email: consultInfo?.email || email,
                reason: "consultation",
                itemId: res?.data?.data?._id,
                callbackUrl: `${process.env.REACT_APP_FRONTEND_URL}doctors/consultsuccess`,
                amount: doc?.fee
            });
            const reference = paymentUrlResponse?.data?.data?.reference;
            const url = paymentUrlResponse?.data?.data?.authorization_url;
            localStorage.setItem("psRef", reference);
            window.open(url, '_self');
            setAppLoading(false);
            localStorage.setItem("form1Data", JSON.stringify({}))
            localStorage.setItem("formData", JSON.stringify({}))
            localStorage.setItem("profile", JSON.stringify({}))

        } catch (error) {
            displayAlert("error", "Initiating consultation failed!");
            console.error("Failed to create consultation", error);
            setAppLoading(false);
            setLoading(false);
        }
    }

    return (
        <div className='bg-secondary mid:justify-center justify-start mid:items-center w-full mid:h-screen min-h-screen flex mid:flex-row flex-col px-[18px] mid:px-0'>
            <Arrow className='mid:hidden block my-[19px] cursor-pointer w-6 h-6' onClick={() => navigate('/doctor/form')} />
            <div className='bg-white mid:rounded-[16px] heala-border w-full mid:w-[912px] h-full mid:h-[auto]'>
                <div className='flex w-full h-full '>
                    <div className='hidden mid:block w-[31%] h-full border-r border-[#CAC4D0] px-4'>
                        <Arrow className='my-[19px] cursor-pointer' onClick={() => navigate('/doctor/form')} />
                        <img src={doc?.picture} alt='doc' className='w-[256px] h-[260px] rounded-lg mid:mb-0 mb-4' />
                        <div className='pt-4 pb-6 px-5 flex flex-col gap-1'>
                            <h5 className='text-[16px] font-medium text-[#0C1322]'>Profile </h5>
                            <p className='text-[#0C1322] text-[16px]'>Dr. {doc?.firstName} {doc?.lastName}</p>
                            <p className='text-[11px] text-tertiary'>{doc?.specialization}</p>
                            <div className='text-tertiary flex gap-1 items-center'>
                                <p className='text-[13px]'>{Number.isInteger(doc?.rating) ? `${doc?.rating}.0` : doc?.rating}</p>
                                <div className='flex gap-1'>
                                    {[...Array(Math.floor(doc?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                </div>
                            </div>
                            <div className='rounded-lg bg-secondary p-2 heala-border flex items-center'>
                                <p className='text-[13px]'>Consultation fee :</p>
                                <p className='text-[16px] text-primary font-medium ml-[7px]'>N{numberWithCommas(doc?.fee)}</p>
                            </div>
                            <p className='text-tertiary text-[13px]'>Heala ID : {doc?.dociId ? doc.dociId.substring(6) : ''}</p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-[5px] flex-1 py-6 px-4 mid:px-11 mid:py-[19px]'>
                        <div className='mb-[3px]'>
                            <h3 className='text-[20px] font-medium'>Booking Preference</h3>
                            <p className='text-[13px]'>Pick a suitable time and communication channel for your consultation</p>
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Select a date</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {/* <DatePicker
                                        sx={{width: '100%'}}
                                        open={isOpen}
                                        onOpen={handleOpen}
                                        onClose={handleClose}
                                        defaultValue={dayjs()}
                                        views={['day']}
                                        disablePast
                                        onChange={handlegetDoctors}
                                    /> */}
                                    <DesktopDatePicker

                                        onChange={handlegetDoctors}
                                        // views={}
                                        disablePast
                                        defaultValue={dayjs()}


                                        style={{ height: "5rem !important" }}

                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Select a time</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    value={time}
                                    onChange={(e) => {
                                        setTime(e.target.value)
                                        setTimeError('')
                                    }
                                    }
                                >
                                    {selectedSlot.length > 0 ? selectedSlot.map((t) => (
                                        <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem>
                                    )) : <MenuItem>No available slot</MenuItem>}
                                </Select>
                            </FormControl>
                            {timeError && <p className='text-error'>{timeError}</p>}
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Communication Channel</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    className={classes.error}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={channel}
                                    onChange={(e) => {
                                        setChannel(e.target.value)
                                        setChannelError('')
                                    }
                                    }
                                >
                                    <MenuItem value="chat">Chat</MenuItem>
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="voice">Voice</MenuItem>
                                </Select>
                            </FormControl>
                            {channelError && <p className='text-error'>{channelError}</p>}
                        </div>

                        <div className='mb-10 mid:mb-0'>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Email Address</h3>
                            <Input
                                type="email"
                                placeholder="Email Address"
                                fullWidth
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    setEmailError('')
                                }
                                }
                                style={{ marginBottom: '5px' }}
                            />
                            {emailError && <p className='text-error'>{emailError}</p>}
                        </div>

                        <Button
                            variant='contained'
                            onClick={handleNext}
                            className={classes.submitButton}
                        >
                            {loading ?
                                (<CircularProgress
                                    style={{ width: 20, height: 20, color: "#fff" }}
                                />)
                                : 'Next'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
