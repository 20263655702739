import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Chip, TextField } from "@mui/material";
import { useQuery } from '@apollo/client';
import { GET_ISSUES } from '../../api/graphQL/query';
import { Button, TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { numberWithCommas } from '../../components/helperFunctions';

const useStyles = makeStyles(() => ({
    submitButton: {
        marginTop:"18px",
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
            height:'44px'
        },
    },
}));

export const DocForm = () => {
    const classes = useStyles();
    const data = localStorage.getItem('doctor');
    const doc = JSON.parse(data);
    const navigate = useNavigate();
    const form1 = JSON.parse(localStorage.getItem('form1Data'));
    const [symptoms, setSymptoms] = useState(form1?.symptoms || '');
    const [level, setLevel] = useState(form1?.level || '');
    const [expect, setExpect] = useState(form1?.expect || '');
    const [aob, setAob] = useState(form1?.aob || '');
    const [batches, setBatches] = useState(form1?.batches || []);
    const [levelError, setLevelError] = useState('');
    const [expectError, setExpectError] = useState('');
    const [batchesError, setBatchesError] = useState('');
    const [filteredIssues, setFilteredIssues] = useState([]);
    
    const { error, data: issuesData, loading: issuesLoading } = useQuery(GET_ISSUES);
    const issues = issuesData?.getIssues?.issues;

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && symptoms.trim() !== '') {
        setBatches([...batches, symptoms.trim()])
        setSymptoms('')
        }
    }

    const handleChipDelete = (chipToDelete) => () => {
        setBatches(batches.filter((chip) => chip !== chipToDelete))
    }

    const handleSymptomChange = (event) => {
        const value = event.target.value.trim();
        if (value === "") {
            setSymptoms("");
            setFilteredIssues([]);
            return;
        }
        
        setSymptoms(value);
        
        const symptomStringCase = value[0].toUpperCase() + value.slice(1).toLowerCase();
        setSymptoms(symptomStringCase);

        const filtered = issues.filter((issue) =>
            issue.Name.includes(symptomStringCase)
        );
        setFilteredIssues(filtered);
        setBatchesError('');
    };

    const handleSymptomClick = (item) => {
        setSymptoms(item.Name);
        setFilteredIssues([]);
        setSymptoms("");
        setBatches((batches) => [item.Name, ...batches]);
    };

    const handleSymptomClick1 = (item) => {
        setSymptoms(item);
        setFilteredIssues([]);
        setSymptoms("");
        setBatches((batches) => [item, ...batches]);
    };
    
    const handleBatchRemove = (id) => {
        setBatches((batches) => [...batches.filter((_, idx) => idx !== id)]);
    };

    const handleNext = () => {
        if (!level) {
            setLevelError('This field is required!');
        }
        if (!expect) {
            setExpectError('This field is required!');
        }
        if (batches.length === 0 && !symptoms) {
            setBatchesError('This field is required!');
        }

        if (!level || !expect || (batches.length === 0 && !symptoms)) {
            return;
        }

        const formData = {
            level,
            expect,
            aob,
            batches,
            symptoms
        };
        localStorage.setItem('form1Data', JSON.stringify(formData)); 
        navigate('/doctor/preference');
    }

    return (
        <div className='bg-secondary mid:justify-center justify-start mid:items-center w-full mid:h-screen min-h-screen flex mid:flex-row flex-col px-[18px] mid:px-0'>
            <Arrow className='mid:hidden block my-[19px] cursor-pointer w-6 h-6' onClick={() => navigate('/doctor')}/>
            <div className='bg-white mid:rounded-[16px] heala-border w-full mid:w-[912px] h-full mid:h-[auto]'>
                <div className='flex w-full h-full '>
                    <div className='hidden mid:block w-[31%] h-full border-r border-[#CAC4D0] px-4'>
                        <Arrow className='my-[19px] cursor-pointer' onClick={() => navigate('/doctor')}/>
                        <img src={doc?.picture} alt='doc' className='w-[256px] h-[260px] rounded-lg mid:mb-0 mb-4' />
                        <div className='pt-4 pb-6 px-5 flex flex-col gap-1'>
                            <h5 className='text-[16px] font-medium text-[#0C1322]'>Profile </h5>
                            <p className='text-[#0C1322] text-[16px]'>Dr. {doc?.firstName} {doc?.lastName}</p>
                            <p className='text-[11px] text-tertiary'>{doc?.specialization}</p>
                            <div className='text-tertiary flex gap-1 items-center'>
                                <p className='text-[13px]'>{Number.isInteger(doc?.rating) ? `${doc?.rating}.0` : doc?.rating}</p>
                                <div className='flex gap-1'>
                                    {[...Array(Math.floor(doc?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                </div>
                            </div>
                            <div className='rounded-lg bg-secondary p-2 heala-border flex items-center'>
                                <p className='text-[13px]'>Consultation fee :</p>
                                <p className='text-[16px] text-primary font-medium ml-[7px]'>N{numberWithCommas(doc?.fee)}</p>
                            </div>
                            <p className='text-tertiary text-[13px]'>Heala ID : {doc?.dociId ? doc.dociId.substring(6) : ''}</p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-[5px] flex-1 py-6 px-4 mid:px-11 mid:py-[19px]'>
                        <div className='mb-[3px]'>
                            <h3 className='text-[20px] font-medium'>Symptom Information</h3>
                            <p className='text-[13px]'>Tell us what the problem is</p>
                        </div>
                        
                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">What are your symptoms?</h3>
                            <div>
                                <div className='chip-input-container'>
                                    <div className="chips-input font-euclid">
                                        {batches.map((chip, index) => (
                                            <Chip
                                                key={index}
                                                label={chip}
                                                onDelete={handleChipDelete(chip)}
                                                className="chip"
                                            />
                                        ))}
                                        <TextField
                                            onChange={handleSymptomChange}
                                            variant="standard"
                                            value={symptoms}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    setSymptoms(symptoms);
                                                    setFilteredIssues([]);
                                                    handleSymptomClick1(e.target.value);
                                                }
                                            }}
                                            // onKeyPress={handleInputKeyPress}
                                            fullWidth
                                            InputProps={{disableUnderline: true}}

                                        />
                                    </div>
                                </div>
                                {filteredIssues.length > 0 && (
                                    <div
                                        className="heela-symptoms-container absolute z-[9999]"
                                        style={{ padding: "1rem" }}
                                    >
                                        {filteredIssues.map((item) => (
                                            <div
                                                key={item.ID}
                                                className="heela-symptoms-option"
                                                onClick={() => handleSymptomClick(item)}
                                            >
                                                {item.Name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {batchesError && <p className='text-error'>{batchesError}</p>}
                        </div>
                        
                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">What is your discomfort level?</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={level}
                                    onChange={(e) =>
                                        { 
                                            setLevel(e.target.value)
                                            setLevelError('')
                                        }
                                    }
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Mild">Mild</MenuItem>
                                    <MenuItem value="Medium">Medium</MenuItem>
                                    <MenuItem value="Intense">Intense</MenuItem>
                                </Select>
                            </FormControl>
                            {levelError && <p className='text-error'>{levelError}</p>}
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">When did you start experiencing the symptoms?</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={expect}
                                    onChange={(e) =>
                                        { 
                                            setExpect(e.target.value)
                                            setExpectError('')
                                        }
                                    }
                                >
                                    <MenuItem value="This week">This week</MenuItem>
                                    <MenuItem value="Last week">Last week</MenuItem>
                                    <MenuItem value="One month ago">One month ago</MenuItem>
                                </Select>
                            </FormControl>
                            {expectError && <p className='text-error'>{expectError}</p>}
                        </div>

                        <div className='mb-10 mid:mb-0'>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Anything else?</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <TextareaAutosize
                                    id="outlined-basic" 
                                    variant="outlined"
                                    value={aob} 
                                    onChange={(e) => setAob(e.target.value)}
                                    aria-label="minimum height"
                                    minRows={2.1}
                                    style={{ width: '100%', fontSize: '16px', padding: '8px', outline: 'none', border:"1px solid #c4c4c4", borderRadius: "8px"}}
                                />
                            </FormControl>
                        </div>

                        <Button
                            variant='contained'
                            onClick={handleNext}
                            className={classes.submitButton}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
