import React from 'react';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import { Avatar } from '@mui/material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatDateTime, convertFirstLetterToUpperCase, classNames, statusStyles, stringAvatar, numberWithCommas } from '../helperFunctions';

const useStyles = makeStyles(() => ({
    submitButton: {
        padding: "14px 20px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        width: '100% !important',
        borderColor: "#3E5EA9 !important",
        color: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important"
    },
}));

export default function Consultsummary({ setOpen, rowData, setPref, pref }) {
    const classes = useStyles();
    const { formattedDate, formattedTime } = formatDateTime(rowData?.time);

    let x = rowData?.time?.split('').splice(16, 5).join('') ? rowData.time?.split('').splice(16, 5).join('') : '11:00'

    const [hours, minutes] = x.split(':').map(Number);

    // Calculate the new hours and minutes
    const newHours = (hours + 1) % 24;
    const newMinutes = minutes;

    // Format the new time with leading zeros
    const newTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;



    return (
        <div className='flex flex-col font-euclid h-full mt-12'>
            <div className="cursor-pointer flex justify-between items-center mx-6">
                <h3 className='font-medium text-[25px]'>Appointment Summary</h3>
                <Exit onClick={() => setOpen(false)} />
            </div>
            <div className='mt-6 md:mt-10 h-full flex flex-col'>
                <div className='flex flex-col md:flex-row justify-between mx-6 gap-6 md:gap-0'>
                    <div className='flex gap-3'>
                        <div className='flex self-baseline'>
                            {rowData?.doctorData?.picture ? (
                                <img src={rowData?.doctorData?.picture} alt='doctor' className="w-16 h-16 rounded-full mt-1" />
                            ) : (
                                <Avatar {...stringAvatar(rowData?.doctorData?.lastName, rowData?.doctorData?.firstName)} sx={{ fontSize: "15px", fontFamily: "Euclid Circular A" }} />
                            )}
                        </div>
                        <div>
                            <h3 className="font-medium text-[25px]">{rowData?.doctorData?.lastName} {rowData?.doctorData?.firstName}</h3>
                            <p className="text-[16px]">{rowData?.doctorData?.specialization}</p>
                            {rowData?.doctorData?.rating && (
                                <div className='flex items-center gap-1'>
                                    {[...Array(Math.floor(rowData?.doctorData?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                    <p className="text-[11px]">{Number.isInteger(rowData?.doctorData?.rating) ? `${rowData?.doctorData?.rating}.0` : rowData?.doctorData?.rating}</p>
                                </div>
                            )}
                            <div className='hidden md:flex items-center gap-1 mt-4 cursor-pointer'>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{formattedDate}</div>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{newTime}</div>
                                {rowData?.consultationDuration && <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{Math.round((rowData?.consultationDuration) / 60)}mins</div>}
                                {rowData?.contactMedium && <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{convertFirstLetterToUpperCase(rowData?.contactMedium)}</div>}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* {rowData?.status === 'completed' && (
                            <Button variant='outlined' className={classes.submitButton} onClick={() => setPref(!pref)}>Request Follow-up</Button>
                        )} */}
                        <div className='flex md:hidden items-center gap-1 mt-6 cursor-pointer flex-wrap'>
                            <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{formattedDate}</div>
                            <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{formattedTime}</div>
                            {rowData?.consultationDuration && <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{rowData?.consultationDuration}</div>}
                            <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{convertFirstLetterToUpperCase(rowData?.contactMedium)}</div>
                        </div>

                        <h3 className='font-medium text-base mt-6 md:mt-4 text-[#000000]'>Consultation Fee: <span className='text-primary'>N{rowData?.fee === 0 ? "Nil" : numberWithCommas(rowData?.fee)}</span></h3>
                        <div className='mt-2 flex gap-2 items-center md:justify-center'>
                            <p className='font-medium text-base text-[#000000]'>Status:</p>
                            <p className={classNames(
                                statusStyles[convertFirstLetterToUpperCase(rowData?.status)],
                                'p-1 rounded-lg'
                            )}>
                                {convertFirstLetterToUpperCase(rowData?.status)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='mt-10 mx-6'>
                    <h3 className='font-medium text-[25px]'>Symptom Information</h3>
                    <div className='mt-6 grid-cols-1 md:grid-cols-2 grid gap-y-7'>
                        <div className='flex flex-col gap-2' >
                            <h5 className='font-medium text-base'>Symptoms</h5>
                            <p>{rowData?.symptoms.map(s => s.name).join('; ')}</p>
                        </div>
                        <div className='flex flex-col gap-2' >
                            <h5 className='font-medium text-base'>Level of discomfort</h5>
                            <p>{rowData?.discomfortLevel}</p>
                        </div>
                        <div className='flex flex-col gap-2' >
                            <h5 className='font-medium text-base'>Duration</h5>
                            <p>{rowData?.firstNotice}</p>
                        </div>
                        <div className='flex flex-col gap-2' >
                            <h5 className='font-medium text-base'>Additional Information</h5>
                            <p>{rowData?.description ? rowData?.description : 'nil'}</p>
                        </div>
                    </div>
                </div>

                <div className='mt-10 bg-status-350 flex-1'>
                    <div className='p-6'>
                        <h3 className='font-medium text-[25px]'>Diagnosis</h3>
                        <div className='mt-6 grid-cols-2 grid gap-y-7'>
                            <div className='flex flex-col gap-2' >
                                <h5 className='font-medium text-base'>Title</h5>
                                <p>{rowData?.diagnosis ? rowData?.diagnosis.map(s => s.ailment).join('; ') : 'Nil'}</p>
                            </div>
                            <div className='flex flex-col gap-2' >
                                <h5 className='font-medium text-base'>Severity</h5>
                                <p>{rowData?.diagnosis ? rowData?.diagnosis.map(s => s.severity) : 'Nil'}</p>
                            </div>

                            <div className='flex flex-col gap-2' >
                                <h5 className='font-medium text-base'>Feedback</h5>
                                <p>{rowData?.doctorNote ? rowData?.doctorNote : 'Nil'}</p>
                            </div>

                            <div className='flex flex-col gap-2' >
                                <h5 className='font-medium text-base'>Recommendation</h5>
                                <p>Nil</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
