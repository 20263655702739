import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import { ReactComponent as Search } from '../../assets/svgs/search.svg';
import { ReactComponent as Exit } from '../../assets/svgs/exi.svg';
import { ReactComponent as Exits } from '../../assets/svgs/exi2.svg';
import { ReactComponent as Menus } from '../../assets/svgs/menu.svg';
import { ReactComponent as Empty } from '../../assets/svgs/error.svg';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { ConsultationContext } from '../../context/consultationContext';
import Tabledashboard from '../table/table.js';
import Drawers from '../drawers';
import Filter from '../filter/filter';
import Menu from '../menu/menu';

const useConsultStyles = makeStyles(() => ({
    submitButton: {
        padding: "16px 14px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontFamily:"inherit !important",
        fontSize: "13px !important"
    },
}));

export default function Consult(
    {
        setMenu, 
        menu, 
        setActiveItem,
        activeItem, 
        openProfile, 
        page, 
        setOpenProfile, 
        setPage,
        setFilterCDoctor,
        setFilterCChannel,
        setFilterCStatus,
        consultationLoading,
        profileLoading
    }
) {
    const navigate = useNavigate();
    
    const classes = useConsultStyles();
    const { consultation, consult2 } = useContext(ConsultationContext) 
    const [open, setOpen] = useState(false);
    const [chip, setChip] = useState(false);
    const [chipData, setChipData] = useState([]);
    const [filteredDoctor, setFilteredDoctor] = useState([]);
    const consult = 'consult';
    const consultData = consultation?.data;
    const tableHead = ['Date', 'Time', 'Channel', 'Doctor Name', 'Cost', 'Status', 'Action' ];
    const doctor = localStorage.getItem('isDoctor');
    const storedData = sessionStorage.getItem('chipData');
    // console.log({storedData})
    useEffect(() => {
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setChipData(parsedData);
            setChip(parsedData.length > 0);
        }
    }, [storedData]);
    
    useEffect(() => {
        if (consultData) {
            const fetchData= () => {
                const extractedDoctors = extractDoctors(consultData);
                setFilteredDoctor(extractedDoctors);
            };
            fetchData();
        }
    }, [consultData]);
      
    const extractDoctors = (data) => {
        const doctors = {};
        for (const item of data) {
            const { doctorData } = item;
            if (doctorData && doctorData._id && doctorData.firstName && doctorData.lastName) {
                const { _id, firstName, lastName } = doctorData;
                if (!doctors[_id]) {
                    doctors[_id] = { _id, firstName, lastName };
                }
            }
        }
        const filterDoctor = Object.values(doctors); 
        return filterDoctor;
    };

    const handleChipDelete = () => {
        setChipData([]);
        setChip(false);
        sessionStorage.removeItem('chipData');
        setFilterCDoctor(''); 
        setFilterCChannel(''); 
        setFilterCStatus('');     
    }
    
    const handleRemoveChip = (c) => {
        setChipData((prevData) => {
            const updatedData = prevData.filter((chip) => {
                return JSON.stringify(chip) !== JSON.stringify(c);
            });
        
            if (updatedData.length === 0) {
                sessionStorage.removeItem('chipData');
            } else {
                sessionStorage.setItem('chipData', JSON.stringify(updatedData));
            }
        
            const remainingDoctor = updatedData.find((chip) => chip.doctor)?.doctor;
            const remainingChannel = updatedData.find((chip) => chip.channel)?.channel;
            const remainingStatus = updatedData.find((chip) => chip.status)?.status;
        
            setFilterCDoctor(remainingDoctor || '');
            setFilterCChannel(remainingChannel || '');
            setFilterCStatus(remainingStatus || '');
        
            return updatedData;
        });
    };            
    
    const handleConsult = () => {
        navigate('/doctor');
    }

    return (
        <>
            <div className='flex items-center gap-4'>
                <Menus className='desktop:hidden' onClick={() => setMenu(!menu)} />
                <h3 className='font-medium text-[25px]'>Consultations</h3>
            </div>

            {consult2?.data?.length === 0 ? (
                <div className='flex justify-center h-full w-full'>
                    <div className='flex flex-col items-center justify-center h-[91%] w-2/5'>
                        <Empty />
                        <h3 className='-mt-6 text-[25px] font-medium font-euclid'>No Consultations</h3>
                        <p className='text-center font-euclid text-gray-400'>You can create a consultation by clicking the button below</p>
                        <Button className={classes.submitButton} sx={{color: "#ffffff", width: "60%", marginTop: "24px"}} onClick={() => navigate('/bookconsultation')}>Schedule Consultation</Button>
                    </div>
                </div>
            ) : (    
                <>
                    <div className='mt-6 tab:mt-5 laptop:mt-8 flex flex-col tab:flex-row gap-6 tab:gap-4 tab:items-center'>
                        {/* <div className='flex'>
                            <Search className='absolute mx-6 my-3'/>
                            <input placeholder='Search' className='focus:outline-none placeholder:text-gray-700 w-full tab:w-[362px] py-3 pr-6 pl-14 border rounded-lg border-gray-50'/>
                        </div> */}
                        <p className='font-medium text-primary text-[13px] cursor-pointer hover:opacity-75 transition ease-out' onClick={() => setOpen(!open)}>Filters</p>
                    </div>
                    {chip && (
                        <div className='flex flex-wrap mt-4 gap-2 filter-chip'>
                            {chipData.map((c) => {
                                const label = c.name;
                                const value = c[Object.keys(c)[0]];

                                return (
                                    <Chip
                                        key={label}
                                        variant="outlined"
                                        label={label}
                                        icon={<Exit onClick={() => handleRemoveChip(c)} />}
                                        sx={{
                                            borderColor: '#EDEDED',
                                            flexDirection: 'row-reverse',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            fontFamily: 'Euclid Circular A',
                                            cursor: 'pointer',
                                        }}
                                    />
                                );
                            })}
                            {chipData.length > 1 && (
                                <Chip
                                    variant='filled'
                                    label='Clear all'
                                    onClick={handleChipDelete}
                                    icon={<Exits />}
                                    sx={{
                                        backgroundColor: '#3E5EA9',
                                        color: 'white',
                                        flexDirection: 'row-reverse',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        fontFamily: 'Euclid Circular A',
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </div>
                    )}
                    <div className='w-full flex justify-start md:justify-end mt-8 md:-mt-14 mb-6'>
                        <Button variant='contained' className={classes.submitButton} onClick={handleConsult}>Start New Consultation</Button>
                    </div>
                    
                    <Tabledashboard profileLoading={profileLoading} tableHead={tableHead} index={consult} setPage={setPage} page={page} loading={consultationLoading}/>
                    
                    <Drawers openDrawer={open} setOpenDrawer={setOpen}>
                        <Filter 
                            setOpen={setOpen} 
                            filter='consult' 
                            setChip={setChip} 
                            chipData={chipData} 
                            setChipData={setChipData} 
                            filteredDoctor={filteredDoctor}
                            setFilterCDoctor={setFilterCDoctor}
                            setFilterCChannel={setFilterCChannel}
                            setFilterCStatus={setFilterCStatus}
                        />
                    </Drawers>
                </>
            )}
            <Drawers openDrawer={menu} setOpenDrawer={setMenu} side="left" width='298px' minwidth='298px'>
                <div className='px-8 pt-10 h-full'>
                    <Menu activeItem={activeItem} setActiveItem={setActiveItem} menu={menu} setMenu={setMenu} openProfile={openProfile} setOpenProfile={setOpenProfile}/>
                </div>
            </Drawers>
        </>
    )
}